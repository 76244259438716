













































import { Observer } from 'mobx-vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Observer
@Component({
  components: {
    'og-card': () => import('@/modules/common/components/posts/og-card.vue'),
  },
})
export default class extends Vue {
  @Prop({
    default: () => {
      return {}
    },
  })
  editorBlock

  @Prop({ default: false }) isEditPost

  @Prop({
    default: () => {
      return {}
    },
  })
  editorHandler

  urlLink = ''
  get isValidLink() {
    const result =
      /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(
        this.urlLink?.toLowerCase()
      )
    return result
  }
  async mounted() {
    if (this.isEditPost) {
      await this.editorHandler.insertUrl(this.editorBlock.link, this.editorBlock.id)
    }
  }

  linkInserting = false

  async insertUrl() {
    this.linkInserting = true
    const validLink = this.urlLink.substring(0, 5).toLowerCase() + this.urlLink.slice(5)
    await this.editorHandler.insertUrl(validLink, this.editorBlock.id)
    this.linkInserting = false
  }

  resetInsertOgInfo() {
    this.urlLink = ''
    this.editorHandler.resetInsertOgInfo(this.editorBlock.id)
  }

  removeEditBlock() {
    const index = this.editorHandler.editorBlocks.findIndex((item) => item.id === this.editorBlock.id)
    if (index === -1) return
    if (
      this.editorHandler.editorBlocks[index - 1]?.type === 'text-block' &&
      this.editorHandler.editorBlocks[index + 1]?.type === 'text-block'
    ) {
      const ref1 = this.$parent.$refs[`quill-${this.editorHandler.editorBlocks[index - 1].id}`] as any
      const ref2 = this.$parent.$refs[`quill-${this.editorHandler.editorBlocks[index + 1].id}`] as any
      const quill1 = ref1[0].quill as any
      const quill2 = ref2[0].quill
      const originalContent = quill1.getContents(0).ops || []
      const appendedContent = quill2.getContents(0).ops || []

      if (originalContent[0]) {
        originalContent[0].insert += '\n'
      }

      const mergedContent = { ops: [...originalContent, ...appendedContent] }
      quill1.setContents(mergedContent)
      this.editorHandler.removeEditorBlocks(index, 2)
    } else {
      this.editorHandler.removeEditorBlocks(index, 1)
    }

    this.urlLink = ''
  }
}
